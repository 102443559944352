/** @jsx jsx */
import { jsx, Button, Text, Container, Flex, useColorMode } from 'theme-ui';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { motion, AnimatePresence } from 'framer-motion';
import { Stack, Grid, Col } from '../components/grid';
import { Dropdown, DropdownItem } from '../components/dropdown';

import { licenceVariants } from '../components/typeface-shop/constants';

import { TextF5 } from '../components/text-f5';

import GraphQLErrorList from '../components/graphql-error-list';

import { useCart, useCartTotal, useTypefaceTotal } from '../cart-provider';
import { useFontLoader } from '../woff-provider/use-font-loader';

const ShopTotal = ({ isEmpty = true, isReady = false }) => {
  const cartTotal = useCartTotal();

  return (
    <AnimatePresence initial={false}>
      {!isEmpty && (
        <motion.div
          initial="closed"
          animate={'open'}
          exit="closed"
          sx={{ overflow: 'hidden', position: 'relative' }}
          variants={{
            open: { height: 'auto' },
            closed: { height: 0 },
          }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        >
          <div
            sx={{
              position: 'sticky',
              top: 0,
              py: 12,
              zIndex: 20,
            }}
          >
            <Flex
              sx={{
                flex: 1,
                width: '100%',
                flexDirection: 'column',
                gap: [10],
              }}
            >
              <p sx={{ align: 'center' }}>
                <Text as="span" variant="h2" sx={{ align: 'center' }}>
                  Total: £{cartTotal}
                </Text>
              </p>

              {!isEmpty && isReady && (
                <motion.a href="#/checkout" sx={{ variant: 'buttons.primary_small' }}>
                  Checkout
                </motion.a>
              )}
            </Flex>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const SectionTitle = ({ typeface }) => {
  const total = useTypefaceTotal(typeface.slug.current);
  return (
    <Flex sx={{ justifyContent: 'space-between', alignItems: 'baseline', pb: [6] }}>
      <Link
        to={`/${typeface._type === 'nursery' ? 'nursery' : 'type'}/${typeface.slug.current}#shop`}
      >
        <TextF5
          font={typeface.banner.font._id}
          sx={{ fontSize: typeface.title.includes('Botanic') ? 7 : 9 }}
        >
          {typeface.title}
        </TextF5>
      </Link>
      <Text variant="h3">£{total}</Text>
    </Flex>
  );
};

const ProductDevice = ({ typeface, device, product, cartItem, onRemove, onRemoveError }) => {
  const { cart } = useCart();
  const [isItemOnCart, setIsItemOnCart] = useState(false);
  const [mode] = useColorMode();

  const { title, subtitle, slug, description, isFamily, font } = product;
  const productID = `${slug.current}-${device}`;
  const variants = licenceVariants[device];
  const licence = product.licence[device];
  const licenceTitle = cartItem ? cartItem.customFields[0].displayValue : '';

  const isInCart = cart[productID];

  useEffect(() => {
    setIsItemOnCart(isInCart);
  }, [isInCart]);

  const licenceTierIndex = useMemo(() => {
    const index = variants.findIndex((v) => v === licenceTitle);
    return index > -1 ? index : 0;
  }, [variants, cart]);

  const categories = useMemo(() => {
    const familyOrStyleCategory = isFamily ? 'family' : 'style';
    return [
      slug.current,
      typeface,
      device,
      familyOrStyleCategory,
      `${typeface}-${familyOrStyleCategory}`,
      `${typeface}-${device}`,
      `${typeface}-${familyOrStyleCategory}-${device}`,
    ];
  }, [typeface, device, isFamily]);

  const { displayPrice, preDiscountPrice } = useMemo(() => {
    const t_discount = licence[`t${licenceTierIndex}_discount`];
    const t_price = licence[`t${licenceTierIndex}_value`];

    let displayPrice;
    let preDiscountPrice = t_discount ? t_price : null;

    displayPrice = t_discount ? t_discount : t_price;

    return { displayPrice, preDiscountPrice, licence };
  }, [licence, licenceTierIndex]);

  const options = useMemo(
    () =>
      variants.reduce((res, cur, index) => {
        const t_discount = licence[`t${index}_discount`];
        const t_price = licence[`t${index}_value`];

        const price = t_discount || t_price;
        res.push({ name: cur, priceModifier: price });
        return res;
      }, []),
    [licence, variants]
  );

  const removeFromCart = useCallback(async () => {
    try {
      if (onRemove) onRemove();

      const state = await window.Snipcart.store.getState();
      const item = state.cart.items.items.find((i) => i.id === productID);
      if (!item) return;

      await window.Snipcart.api.cart.items.remove(item.uniqueId);
    } catch (error) {
      if (onRemoveError) onRemoveError();
    }
  }, [productID, onRemove, onRemoveError]);

  const updateItem = useCallback(
    async (option) => {
      try {
        const state = await window.Snipcart.store.getState();
        const item = state.cart.items.items.find((i) => i.id === productID);

        if (!item) return;

        const customField = item.customFields.find((f) => f.name === 'Licence');

        await window.Snipcart.api.cart.items.update({
          ...item,
          customFields: [{ ...customField, value: option.name }],
        });
      } catch (error) {}
    },
    [productID, options]
  );

  const addToCart = useCallback(async () => {
    try {
      const option = options[0];
      const data = {
        id: productID,
        url: '/products',
        basePrice: 0,
        categories: categories,
        name: `${title} (${device})`,
        quantity: 1,
        maxQuantity: 1,
        taxable: true,
        shippable: false,
        stackable: false,
        fileGuid: product[`guid_${device}`].trim(),
        customFields: [
          {
            displayValue: option.name,
            operation: option.priceModifier,
            name: 'Licence',
            options: options,
            optionsArray: options.map((p) => p.name),
            required: true,
            type: 'dropdown',
            value: option.name,
          },
        ],
      };
      await window.Snipcart.api.cart.items.add(data);
    } catch (error) {
      console.log(error);
    }
  }, [productID, options]);

  const handleClickMinus = () => {
    const newTierIndex = licenceTierIndex - 1;
    if (newTierIndex < 0) {
      removeFromCart();
      setIsItemOnCart(false);
    } else {
      const newTier = options[newTierIndex];
      updateItem(newTier);
    }
  };
  const handleClickPlus = () => {
    const newTierIndex = Math.min(options.length, licenceTierIndex + 1);
    if (newTierIndex === licenceTierIndex) return;

    const newTier = options[newTierIndex];
    updateItem(newTier);
  };
  const handleMainClick = () => {
    setIsItemOnCart(true);
    if (!isInCart) {
      addToCart();
    }
  };

  return isInCart ? (
    <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
      <Stack>
        <Text variant="small_header" sx={{ textTransform: 'capitalize' }}>
          {device}
        </Text>
        <Text
          variant="small"
          sx={{
            color: 'txt.3',
          }}
        >
          {licenceTitle}
        </Text>
      </Stack>

      <Text variant="small_header" sx={{ textTransform: 'capitalize' }}>
        {isInCart ? `£${displayPrice}` : ''}
      </Text>
    </Flex>
  ) : null;
};

const FreeProductRow = ({ typeface, product }) => {
  const [open, setOpen] = useState(true);

  const { cart } = useCart();
  const [isItemOnCart, setIsItemOnCart] = useState(false);
  const [mode] = useColorMode();

  const { title, subtitle, slug = {}, description, isFamily, font } = product;
  const productID = `${slug.current}-personal`;

  const cardProduct = cart[productID];

  // const handleOnRemove = () => {
  //   if (Object.keys(product.cartItems).length === 1) {
  //     setOpen(false);
  //   }
  // };

  // const handleOnRemoveError = () => {
  //   if (Object.keys(product.cartItems).length > 0) {
  //     setOpen(true);
  //   }
  // };

  const removeFromCart = useCallback(async () => {
    try {
      const state = await window.Snipcart.store.getState();

      const item = state.cart.items.items.find((i) => i.id === product._id);
      if (!item) return;

      await window.Snipcart.api.cart.items.remove(item.uniqueId);

      // handleOnRemove();
    } catch (error) {
      // if (handleOnRemoveError) handleOnRemoveError();
    }
  }, [product]);

  return (
    <motion.article
      key={`${product._id}_cart_inner`}
      initial="closed"
      animate={open ? 'open' : 'closed'}
      exit="closed"
      sx={{ overflow: 'hidden' }}
      variants={{
        open: { height: 'auto', opacity: 1, y: 0 },
        closed: { height: 0, opacity: 0, y: '-1em', transition: { delay: 0 } },
      }}
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 0,
      }}
    >
      <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Stack>
          <Text sx={{ textTransform: 'capitalize', fontWeight: '700', fontSize: 5 }}>
            {product.title}
          </Text>

          {product.subtitle ? (
            <div
              sx={{
                color: 'txt.3',
              }}
            >
              {product.subtitle.map((value, idx) => (
                <Text key={`${idx}-${value}`} variant="small" className="subtitle">
                  {value}
                </Text>
              ))}
              <Text variant="small" className="subtitle">
                1 User, 10,000 Views/Month
              </Text>
            </div>
          ) : null}
        </Stack>

        <Text variant="small_header" sx={{ textTransform: 'capitalize' }}>
          {`£0`}
        </Text>
      </Flex>
    </motion.article>
    // <article>
    //   <div
    //     sx={{
    //       mb: [4],
    //       bg: 'accent',
    //       color: 'txt.4',
    //       transition: 'background-color 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
    //       pt: [6, 7, 8],
    //       pb: [6, 7, 8],
    //       px: [6, 7, 8],
    //     }}
    //   >
    //     <Grid columns={[8]} sx={{ width: '100%', gap: [6, 6], alignItems: 'center' }}>
    //       <Col span={[8, 8, 5, 5]}>
    //         <Text sx={{ fontWeight: '700' }} variant="f5_typeface_product">
    //           {product.product_title}{' '}
    //         </Text>

    //         <Text variant="small" className="subtitle">
    //           {product.product_subtitle}
    //         </Text>
    //       </Col>
    //       <Col />
    //       <Col />
    //       <Col>
    //         <Button variant="invert_tiny" onClick={removeFromCart}>
    //           <Text>Remove from cart</Text>
    //         </Button>
    //       </Col>
    //     </Grid>
    //   </div>
    // </article>
  );
};

const TrialFontsRow = ({ typeface, product }) => {
  const [open, setOpen] = useState(true);

  // const { cart } = useCart();
  // const [isItemOnCart, setIsItemOnCart] = useState(false);
  // const [mode] = useColorMode();

  // const { title, subtitle, slug = {}, description, isFamily, font } = product;
  // const productID = `${slug.current}-personal`;

  // const cardProduct = cart[productID];

  // console.log(cardProduct, cart);

  // const handleOnRemove = () => {
  //   if (Object.keys(product.cartItems).length === 1) {
  //     setOpen(false);
  //   }
  // };

  // const handleOnRemoveError = () => {
  //   if (Object.keys(product.cartItems).length > 0) {
  //     setOpen(true);
  //   }
  // };

  const removeFromCart = useCallback(async () => {
    try {
      const state = await window.Snipcart.store.getState();

      const item = state.cart.items.items.find((i) => i.id === product._id);
      if (!item) return;

      await window.Snipcart.api.cart.items.remove(item.uniqueId);

      // handleOnRemove();
    } catch (error) {
      // if (handleOnRemoveError) handleOnRemoveError();
    }
  }, [product]);

  return (
    <article
      sx={{
        pb: [12],
        borderBottom: 'line',
        borderColor: 'line',
        '&:last-child': {
          borderBottom: 'none',
        },
      }}
    >
      <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Stack>
          <Link to={`/type/trial-fonts`}>
            <Text sx={{ textTransform: 'capitalize', fontWeight: '700', fontSize: 5 }}>
              {product.product_title}
            </Text>
          </Link>

          <Text variant="small" className="subtitle" sx={{ maxWidth: '35ch', color: 'txt.3' }}>
            {product.product_subtitle}
          </Text>
        </Stack>

        <Text variant="small_header" sx={{ textTransform: 'capitalize' }}>
          {`£0`}
        </Text>
      </Flex>
    </article>
  );
};

const CartProductRow = ({ typeface, product }) => {
  useFontLoader(product.font._id);
  const [open, setOpen] = useState(true);

  const handleOnRemove = () => {
    if (Object.keys(product.cartItems).length === 1) {
      setOpen(false);
    }
  };

  const handleOnRemoveError = () => {
    if (Object.keys(product.cartItems).length > 0) {
      setOpen(true);
    }
  };

  return (
    <motion.article
      key={`${product._id}_cart_inner`}
      initial="closed"
      animate={open ? 'open' : 'closed'}
      exit="closed"
      sx={{ overflow: 'hidden' }}
      variants={{
        open: { height: 'auto', opacity: 1, y: 0 },
        closed: { height: 0, opacity: 0, y: '-1em', transition: { delay: 0 } },
      }}
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 0,
      }}
    >
      <Stack sx={{ gap: 5 }}>
        <Stack sx={{ gap: 0 }}>
          <Text sx={{ fontWeight: '700', fontSize: 5 }}>{product.title}</Text>
          {product.subtitle &&
            product.subtitle.map((value, idx) => (
              <Text
                key={`${idx}-${value}`}
                variant="small"
                className="subtitle"
                sx={{ color: 'txt.3' }}
              >
                {value}
              </Text>
            ))}
        </Stack>
        <ProductDevice
          typeface={typeface}
          device="desktop"
          product={product}
          cartItem={product.cartItems.desktop}
          onRemove={handleOnRemove}
          onRemoveError={handleOnRemoveError}
        />

        <ProductDevice
          typeface={typeface}
          device="web"
          product={product}
          cartItem={product.cartItems.web}
          onRemove={handleOnRemove}
          onRemoveError={handleOnRemoveError}
        />

        <ProductDevice
          typeface={typeface}
          device="app"
          product={product}
          cartItem={product.cartItems.app}
          onRemove={handleOnRemove}
          onRemoveError={handleOnRemoveError}
        />

        <ProductDevice
          typeface={typeface}
          device="ebook"
          product={product}
          cartItem={product.cartItems.ebook}
          onRemove={handleOnRemove}
          onRemoveError={handleOnRemoveError}
        />
      </Stack>
    </motion.article>
  );
};

// const TrialFontsRow = ({ typeface, product }) => {
//   const [open, setOpen] = useState(true);

//   // const handleOnRemove = () => {
//   //   if (Object.keys(product.cartItems).length === 1) {
//   //     setOpen(false);
//   //   }
//   // };

//   // const handleOnRemoveError = () => {
//   //   if (Object.keys(product.cartItems).length > 0) {
//   //     setOpen(true);
//   //   }
//   // };

//   const removeFromCart = useCallback(async () => {
//     try {
//       const state = await window.Snipcart.store.getState();

//       const item = state.cart.items.items.find((i) => i.id === product._id);
//       if (!item) return;

//       await window.Snipcart.api.cart.items.remove(item.uniqueId);

//       // handleOnRemove();
//     } catch (error) {
//       // if (handleOnRemoveError) handleOnRemoveError();
//     }
//   }, [product]);

//   return (
//     <article>
//       <div
//         sx={{
//           mb: [4],
//           bg: 'accent',
//           color: 'txt.4',
//           transition: 'background-color 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
//           pt: [6, 7, 8],
//           pb: [6, 7, 8],
//           px: [6, 7, 8],
//         }}
//       >
//         <Grid columns={[8]} sx={{ width: '100%', gap: [6, 6], alignItems: 'center' }}>
//           <Col span={[8, 8, 5, 5]}>
//             <Text sx={{ fontWeight: '700' }} variant="f5_typeface_product">
//               {product.product_title}{' '}
//             </Text>

//             <Text variant="small" className="subtitle">
//               {product.product_subtitle}
//             </Text>
//           </Col>
//           <Col />
//           <Col />
//           <Col>
//             <Button variant="invert_tiny" onClick={removeFromCart}>
//               <Text>Remove from cart</Text>
//             </Button>
//           </Col>
//         </Grid>
//       </div>
//     </article>
//   );
// };

const CartPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <>
        <GraphQLErrorList errors={errors} />
      </>
    );
  }

  const { trials } = data;

  trials._id = 'f5-trial-fonts';

  const { cart, ready } = useCart();

  const typefacesInCart = useMemo(() => {
    const nurseryTransformed = data.nursery.nodes.reduce((acc, nursery) => {
      const res = { ...nursery };

      res.banner = nursery.hero;
      acc.push(res);
      return acc;
    }, []);

    const typefaces = data.typefaces.nodes;

    return [...nurseryTransformed, ...typefaces]
      .map((typeface) => {
        let freeProductInCart = false;

        if (typeface.freebie) {
          freeProductInCart = cart.hasOwnProperty(
            `${typeface.freebie.product.slug.current}-personal`
          );
        }

        const productsInCart = typeface.products.rows
          .map((product) => {
            const cartItems = Object.keys(cart)
              .filter((key) => {
                return key === `${product.slug.current}-desktop`
                  ? true
                  : key === `${product.slug.current}-app`
                  ? true
                  : key === `${product.slug.current}-ebook`
                  ? true
                  : key === `${product.slug.current}-web`
                  ? true
                  : false;
              })
              .filter(Boolean)
              .reduce((res, key) => {
                res[key.split('-').pop()] = cart[key];
                return res;
              }, {});

            return Object.keys(cartItems).length ? { ...product, cartItems: cartItems } : null;
          })
          .filter(Boolean);

        return freeProductInCart || productsInCart.length
          ? { ...typeface, products: productsInCart }
          : null;
      })
      .filter(Boolean);
  }, [cart, data]);

  const trialsInCart = cart[trials._id] ? true : null;

  const isCartEmpty = !trialsInCart && typefacesInCart.length === 0;

  return (
    <>
      <GatsbySeo title={'Cart'} noindex={true} nofollow={true} />

      <Container sx={{ position: 'relative', minHeight: '300px' }} as="section">
        <div
          sx={{
            display: 'grid',
            gap: [10, 10, 12],
            gridTemplateColumns: ['repeat(1, 1fr)', '1fr max-content'],
            gridTemplateAreas: [null, `'items total'`],
          }}
        >
          <div
            sx={{
              gridArea: [null, 'total'],
              borderBottom: ['line', 0],
              borderColor: ['line', 'line'],
            }}
          >
            <ShopTotal isEmpty={isCartEmpty} isReady={ready} />
          </div>
          <div
            sx={{
              gridArea: [null, 'items'],
              pr: [0, 10, 12],
              borderRight: [0, 'line'],
              borderColor: ['line', 'line'],
            }}
          >
            {ready === false ? (
              <Text
                sx={{
                  my: 12,
                  top: 0,
                  position: 'absolute',
                  width: '100%',
                }}
              >
                Loading...
              </Text>
            ) : (
              <>
                <Stack
                  gap={12}
                  sx={{
                    py: [0, 10, 12],
                    top: 0,
                  }}
                >
                  <AnimatePresence initial={true}>
                    {isCartEmpty && ready && (
                      <>
                        <motion.div
                          key={`empty_cart_title`}
                          initial="closed"
                          animate="open"
                          exit="closed"
                          variants={{
                            open: { opacity: 1, y: 0, transition: { delay: 1 } },
                            closed: { opacity: 0, y: '-1em' },
                          }}
                        >
                          <Text>Your cart is empty!</Text>
                        </motion.div>
                        <motion.div
                          key={`empty_cart_browser_fonts`}
                          initial="closed"
                          animate="open"
                          exit="closed"
                          variants={{
                            open: { opacity: 1, y: 0, transition: { delay: 1.2 } },
                            closed: { opacity: 0, y: '-1em' },
                          }}
                        >
                          <Link href="/" sx={{ variant: 'buttons.primary_small' }}>
                            Browse our catalog
                          </Link>
                        </motion.div>
                      </>
                    )}
                    {trialsInCart ? (
                      <>
                        <TrialFontsRow key={trials._id} product={trials} />
                      </>
                    ) : null}
                    {typefacesInCart.map((typeface) => (
                      <motion.div
                        key={`${typeface.slug.current}_cart_group`}
                        initial="open"
                        animate="open"
                        exit="closed"
                        variants={{
                          open: { opacity: 1 },
                          closed: { opacity: 0, delay: 1 },
                        }}
                        transition={{
                          type: 'spring',
                          stiffness: 400,
                          damping: 20,
                        }}
                        sx={{
                          pb: [12],
                          borderBottom: 'line',
                          borderColor: 'line',
                          '&:last-child': {
                            borderBottom: 'none',
                          },
                        }}
                      >
                        <Stack gap={6}>
                          <SectionTitle typeface={typeface} />
                          <Stack gap={10}>
                            <AnimatePresence initial={false}>
                              {typeface.freebie ? (
                                <FreeProductRow
                                  key={typeface.freebie.product._id}
                                  typeface={typeface.slug.current}
                                  product={typeface.freebie.product}
                                />
                              ) : null}
                              {typeface.products.map((product, idx) => (
                                <CartProductRow
                                  key={product._id}
                                  typeface={typeface.slug.current}
                                  product={product}
                                />
                              ))}
                            </AnimatePresence>
                          </Stack>
                        </Stack>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </Stack>
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export const query = graphql`
  query CartPage {
    trials: sanityPageTrials {
      id
      product_title
      product_subtitle
      guid_test
      guid_live
    }
    nursery: allSanityNursery {
      nodes {
        _id
        title
        _type
        hero {
          font {
            _id
          }
        }
        slug {
          ...SanitySlugFragment
        }
        freebie {
          ...SanityTypefaceFreeProductsFragment
        }
        products {
          ...SanityTypefaceProductsFragment
        }
      }
    }
    typefaces: allSanityTypeface {
      nodes {
        _id
        title
        _type
        banner {
          font {
            _id
          }
        }
        slug {
          ...SanitySlugFragment
        }
        products {
          ...SanityTypefaceProductsFragment
        }
      }
    }
  }
`;

export default CartPage;
